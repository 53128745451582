<template>
    <div class="mt-6 px-5 flex-auto rounded-t-2xl bg-white text-gray-600 transition duration-300 ease-in-out">
        <div class="pt-6">
            <div class="relative w-full h-1 mt-4 rounded-lg" style="background-color: #E3E5E5">
                <div class="absolute w-4/12 h-1 bg-wonderful rounded-lg z-10">
                </div>
            </div>
        </div>
        <h1 class="mt-4 text-lg text-center text-brand-black">
            Step 2 of 3
        </h1>
        <h2 class="mt-6 text-2xl text-center text-medium text-brand-black">
            Link to your bank account
        </h2>
        <p class="mt-4 text-center text-brand-black">
            As an FCA authorised<strong>Payment Institution</strong>, we need to link your business bank account
            to the One app for two reasons <strong>ONLY</strong>.
        </p>
        <p class="mt-2 text-sm text-center text-brand-black">
            Under our obligations as an FCA authorised Payment Institution (964289), we must
            <strong>verify account ownership</strong>.<br>
            Once linked, we use this authorisation to send instant bank payments to your account.
        </p>
        <div class="py-6 flex flex-col max-h-full">
            <div class="mt-4">
                <bank-search
                    @bank-selected="bankSelectedHandler"
                    :search-api-url="searchApiUrl"
                    :scope="'accounts'"
                    :suggest-bank-url="suggestBankUrl"
                ></bank-search>
            </div>
        </div>
        <div v-if="errors" class="text-red-600">
            <p v-for="message in errors">{{ message }}</p>
        </div>
<!--        <form class="mt-4 pb-4" @submit.prevent="confirmBank" ref="bankForm">-->

<!--            <button-->
<!--                :disabled="this.selectedBank.name.length === 0"-->
<!--                class="w-full rounded-full bg-wonderful py-2.5 px-4 text-sm text-white">-->
<!--                Confirm-->
<!--            </button>-->
<!--        </form>-->
    </div>
</template>

<script>
import FcaFooterText from "../../pages/FcaFooterText.vue";
import BankSearch from "../BankSearch.vue";
import BankList from "../BankList.vue";

export default {
    name: "BankSelection",
    components: {BankList, FcaFooterText, BankSearch},
    props: {
        searchApiUrl: {
            type: String,
            required: true
        },
        errors: {
            type: Array,
            required: true
        },
        suggestBankUrl: String,
    },
    data: function () {
        return {
            selectedBank: {
                ais_supported_resources: [],
                display_name: '',
                logo: '',
                name: '',
                status: '',
            },
        }
    },
    methods: {
        bankSelectedHandler(bank) {
            this.selectedBank = bank;
            this.$emit('bank-selected', this.selectedBank);
        },
        // confirmBank() {
        //     this.$emit('bank-selected', this.selectedBank);
        // }
    }
}
</script>

<style scoped>

</style>
