<template>
    <div class="px-8 mt-4 mb-10 text-center text-[#E3E5E5] text-xs md:text-sm">
        <p class="mt-4">Payment initiation and account information services are facilitated by Wonderful Payments Limited whose registered office is 41 Luke Street, London EC2A 4DP and who may be contacted <a class="underline" target="_blank" href="https://wonderful.support/ticket/create">here</a>.</p>
        <p class="mt-4">Wonderful Payments Limited is authorised by the Financial Conduct Authority
            as an Authorised Payment Institution with Firm Reference Number: 964289.</p>
        <p class="mt-4">The FCA may be contacted <a class="underline" target="_blank" href="https://wonderful.support/ticket/create">here</a>.</p>
    </div>
</template>
<script>
export default {
    name: 'FcaFooterText',
    data() {
        return {
            showFullFooter: false
        }
    }
}
</script>
<style scoped>

</style>
